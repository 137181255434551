@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.login {
  max-width: 410px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0%;
  background: #fff;
  border: 1px solid #dddfe2;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  text-align: center;
}

.xyz {
  color: #1877f2;
  cursor: pointer;
}

.xyz :hover {
  color: #1d2129;

}

.pdf-container {
  display: flex;
  align-items: center;
  justify-content: center;


  @media (max-width: 795px) {

    margin-left: 20px;
  }

  @media (max-width: 735px) {

    margin-left: 30px;
  }

  @media (max-width: 690px) {

    margin-left: 80px;
  }

  @media (max-width: 650px) {

    margin-left: 120px;
  }


  @media (max-width: 620px) {

    margin-left: 170px;
  }

  @media (max-width: 590px) {

    margin-left: 190px;
  }

  @media (max-width: 550px) {

    margin-left: 220px;
  }

  @media (max-width: 500px) {

    margin-left: 260px;
  }

  @media (max-width: 450px) {

    margin-left: 300px;
  }

  @media (max-width: 420px) {

    margin-left: 350px;
  }
}

audio {
  display: none !important;
  /* Or use another method from below if preferred */
}

.pdf-container {
  display: flex;
  flex-direction: column;
  /* Stack pages vertically */
  align-items: center;
  /* Center pages horizontally */
  gap: 20px;
  /* Optional: Add space between pages */
}

.page-container {
  margin-bottom: 20px;
  /* Space below each page */
}


/*
.pdf-page {
  height: 100vh;
overflow: hidden;
position: relative;
}

.pagination-info {
  margin-top: 10px;
}

*/
.login>.button {
  background: #1877f2;
  border: 1px solid #1877f2;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
}


.Login {
  height: 100%;
  width: 100%;
  /* background-image: url("../../Assests/bg.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;


}

.Login>button {
  background-color: white;
  height: 10px;
  /* color: lightdark; */
  border: none;
  border-radius: 10px;
  padding: 1rem 2rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;

}

.register {
  max-width: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0%;
  background: #fff;
  border: 1px solid #dddfe2;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  text-align: center;
}

.xyz {
  color: #f25d18;
  cursor: pointer;
}

.xyz :hover {
  color: #1d2129;

}




.register>.button {
  background: #1877f2;
  border: 1px solid #1877f2;
  color: #fff;
  font-size: 1.25rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
}

h2 {
  color: #242121;
  font-size: 2.3em;
  justify-content: center;
  display: flex;
  margin: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: .5s ease-in-out;
}

/* // navbar */

.navbar {
  background: #7447a1;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  color: #ffffff;

}

.navbar-custom {
  background: #7447a1;
  height: 50px;
}

.seminavbar-custom {
  background: #7447a1;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: center;
  margin-right: 100px;
}

.nav-item {
  height: 60px;
  border-bottom: 2px solid transparent;
  position: relative;
  top: 28px;
  margin-left: 7px;
  margin-right: 7px;
  font-size: medium;
}


.nav-links {
  color: #e9e4e4;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 10%;
}

.activated {
  color: #09bef0;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

.menu-toogle {
  display: block;
  position: absolute;
  top: -19px;
  right: 30px;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: absolute;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 48px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #19191a;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #09bef0;
    transform: scale(1);
    transition: all 0.3s ease;


  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: -20px;
    left: 0;
    transform: translate(50%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -5px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}


.buttons {

  border-radius: 4px;

  position: relative;
  top: -15px;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: rgb(104, 85, 224);
  background-color: rgba(255, 255, 255, 1);
  border: 1px rgba(104, 85, 224, 1);
  margin: 4px 2px;
  cursor: pointer;
}

.buttons:hover {
  color: white;

  box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
  background-color: rgba(104, 85, 224, 1);
}

.blur,
.blured {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid rgb(9, 59, 196);
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.messaged {
  color: red;
  text-align: center;
}

.avatar {
  width: 45px;
  /* Set your desired width */
  height: 45px;
  /* Set your desired height */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #fff;
  /* font-weight:; */
  margin-top: -5px;
}


/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-link {
  margin-right: 20px;
  text-decoration: none;
  color: #333;
}

.tab-link:hover {
  color: #666;
}

.tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-pane {
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.download-btn {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.download-btn:hover {
  background-color: #3e8e41;
}